import { Container, List, ListItem } from 'semantic-ui-react';
import { useState }from 'react';
import axios from 'axios';

import MenuBar from './MenuBar';
import { staticUrl, sleep, envFromUrl } from './utils';

import { Amplify } from 'aws-amplify';
const env = envFromUrl();
const amplifyconfig = await import(`./amplifyconfiguration/${env}.json`);

Amplify.configure(amplifyconfig);

export default function Resources() {
    const [manifest, setManifest] = useState([]);
    const [gotManifest, setGotManifest] = useState(false);
    // for exponential backoff to prevent constant polling
    const [attemptCount, setAttemptCount] = useState(0);

    const getManifest = async (manifestPath) => {
        const tmpManifest = [];
        await sleep(1000 * (attemptCount ** 2));
        try {
            // this is already deserialised on assignment
            const resourcesResponse = await axios.get(staticUrl(manifestPath));
            for (const responseItem of resourcesResponse.data) {
                if (!responseItem.linkText || (
                    !responseItem.remoteUrl && !responseItem.staticKey
                )) {
                    throw new Error(
                        `must have linkText and one of remoteUrl or staticKey, got ${Object.keys(responseItem)}`
                    );
                }
                tmpManifest.push({
                    linkText: responseItem.linkText,
                    linkUrl: responseItem.remoteUrl ?? staticUrl(responseItem.staticKey)
                });
            }
            setManifest(tmpManifest);
            setGotManifest(true);
        } finally {
            setAttemptCount(attemptCount + 1);
        }

    }
    if (!gotManifest) getManifest("resources.json");
    return (
        <Container>
            <MenuBar />
            <Container>
                <List bulleted>
                    {manifest.map(manifestEntry => (
                        <ListItem>
                            <a href={manifestEntry.linkUrl} target="_blank" rel="noreferrer">
                                {manifestEntry.linkText}
                            </a>
                        </ListItem>
                    ))}
                </List>
            </Container>
        </Container>
    );
}