import { get } from 'aws-amplify/api';
import { useState, useEffect } from 'react';
import {
  Container,
  Loader,
  Table,
} from 'semantic-ui-react';
import MenuBar from './MenuBar';
import { envFromUrl } from './utils';

import { Amplify } from 'aws-amplify';
const env = envFromUrl();
const amplifyconfig = await import(`./amplifyconfiguration/${env}.json`);

Amplify.configure(amplifyconfig);

export default function Rankings() {

    const [rankingsLoading, setRankingsLoading] = useState(true);
    const [attemptedGet, setAttemptedGet] = useState(false);
    const [rankingsPeriods, setRankingsPeriods] = useState([]);

    useEffect(() => {
        const getRankings = async() => {
            try {
                const restOperation = get({
                    apiName: "rankings",
                    path: "/rankings",
                });
                const { body } = await restOperation.response;
                const respObj = await body.json();

                setRankingsPeriods(respObj);
            } finally {
                setAttemptedGet(true);
                setRankingsLoading(false);
            }
        };
        if (!attemptedGet) getRankings();
    });

    function getGroupHeader(group) {
        const sexStr = (group.sex === "F") ? "Female" : "Male";
        let eqStr;
        switch (group.eqClass) {
            case "R":
                eqStr = "Raw";
                break;
            case "E":
                eqStr = "Equipped";
                break;
            case "SL":
                eqStr = "Sleeves";
                break;
            default:
                console.error(`Unknown equipment class ${group.eqClass} trying to translate to UI string`);
                eqStr = group.eqClass;
                break;
        }
        let ageStr;
        const ageClass = group.ageClass;
        if (ageClass === "O") ageStr = "Open";
        else if (ageClass === "J") ageStr = "Junior";
        else if (ageClass.startsWith("T")) ageStr = `Teen ${ageClass[1]}`;
        else if (ageClass.startsWith("M")) ageStr = `Masters ${ageClass[1]}`;
        else {
            console.error(`Unknown age class ${ageClass}`)
        }
        const ret = `${sexStr} ${ageStr} ${eqStr} ${group.weightClass}kg`;
        return ret;
    }

    return (
        <Container>
            <MenuBar />
            <Container>
                <p>
                    <b>NOTE:</b> While rankings provide an indication of&nbsp;
                    priority order of invitations to a National Championship,&nbsp;
                    actual invitations are issued by the Meet Director of that&nbsp;
                    National Championship.  Please watch for communications&nbsp;
                    issued by the Meet Director and contact them with any&nbsp;
                    questions regarding the invitational process.
                </p>
                {rankingsLoading ? (
                    <Loader active inline='centered' />
                ) : (
                    <Container>
                        {rankingsPeriods.map(curRankingsPeriod => (
                            <Container>
                                <h1>{curRankingsPeriod.rankingsPeriodName}</h1>
                                <p>
                                    Qualification period:&nbsp;
                                    {curRankingsPeriod.startQualLocalDateStr}&nbsp;
                                    -&nbsp;
                                    {curRankingsPeriod.endQualLocalDateStr}&nbsp;
                                    -&nbsp; does not include any National Championships held during this period.
                                </p>
                                {curRankingsPeriod.rankingsGroups.map(curRankingsGroup => (
                                    <Table celled>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell colSpan="3">
                                                    {getGroupHeader(curRankingsGroup)}
                                                </Table.HeaderCell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.HeaderCell style={{ width: "50%" }}>Lifter</Table.HeaderCell>
                                                <Table.HeaderCell style={{ width: "25%" }}>Total</Table.HeaderCell>
                                                <Table.HeaderCell style={{ width: "25%"}}>Date</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {curRankingsGroup.entries.map(entry => (
                                                <Table.Row>
                                                    <Table.Cell>{entry.lifterName}</Table.Cell>
                                                    <Table.Cell>{entry.totalKg}</Table.Cell>
                                                    <Table.Cell>{entry.localDateStr}</Table.Cell>
                                                </Table.Row>
                                            ))}
                                        </Table.Body>
                                    </Table>
                                ))}
                            </Container>
                        ))}   
                    </Container>
                )}
            </Container>
        </Container>
    );
}