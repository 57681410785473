import { useState } from 'react';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { post } from 'aws-amplify/api';
import {
  Button,
  Container,
  Form,
  Grid,
  Header,
  Image,
  Message,
  Segment,
} from 'semantic-ui-react';
import { validate } from 'email-validator';
import MenuBar from './MenuBar';
import logo from './gpc-logo.png';
import { envFromUrl } from './utils';

import { Amplify } from 'aws-amplify';
const env = envFromUrl();
const amplifyconfig = await import(`./amplifyconfiguration/${env}.json`);

Amplify.configure(amplifyconfig);

export default function Join() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState({});
  const [formErrors, setFormErrors] = useState([]);
  const [membership, setMembership] = useState('');
  const [name, setName] = useState('');
  const [tandc, setTandc] = useState(false);
  const elements = useElements();
  const stripe = useStripe();

  const createSession = async () => {
    if (!stripe || !elements) {
      setLoading(false);
      setError({});
      return;
    }

    try {
      const restOperation = post({
        apiName: 'stripecheckout',
        path: '/checkout',
        options: { body: { email, membership, name } },
      });
      const { body } = await restOperation.response;
      const data = await body.json();
      if (data.err) {
        setError({
          header: 'Error Occurred.',
          message:
            'An error occurred submitting your information, please try again!',
          type: 'error',
        });
      } else {
        if (data.result !== 'subscription_exists') {
          const { error } = await stripe.redirectToCheckout({
            sessionId: data.session.id,
          });
          if (error) {
            console.log(error);
          }
        } else {
          setError({
            header: 'Membership Exists!',
            message: 'An active membership with this email already exists.',
            type: 'warning',
          });
        }
      }
    } catch (error) {
      console.log(error);
      setError({
        header: 'Error Occurred.',
        message:
          'An error occurred submitting your information, please try again!',
        type: 'error',
      });
    }
    setLoading(false);
  };

  const handleEmailChange = (e, { value }) => {
    setEmail(value);
    if (!validate(value)) {
      setFormErrors([...new Set([...formErrors, 'email'])]);
    } else {
      setFormErrors(formErrors.filter(error => error !== 'email'));
    }
  };
  const handleMembershipChange = (e, { value }) => {
    setMembership(value);
    if (value) {
      setFormErrors(formErrors.filter(error => error !== 'membership'));
    } else {
      setFormErrors([...new Set([...formErrors, 'membership'])]);
    }
  };

  const handleNameChange = (e, { value }) => {
    setName(value);
    if (value) {
      setFormErrors(formErrors.filter(error => error !== 'name'));
    } else {
      setFormErrors([...new Set([...formErrors, 'name'])]);
    }
  };
  const toggleTandc = () => {
    setTandc(!tandc);
    if (!tandc) {
      setFormErrors(formErrors.filter(error => error !== 'tandc'));
    } else {
      setFormErrors([...new Set([...formErrors, 'tandc'])]);
    }
  };

  const handleSubmit = () => {
    const submissionErrors = [];
    if (!email) {
      submissionErrors.push('email');
    }
    if (!membership) {
      submissionErrors.push('membership');
    }
    if (!name) {
      submissionErrors.push('name');
    }
    if (!tandc) {
      submissionErrors.push('tandc');
    }
    if (submissionErrors.length > 0) {
      setFormErrors(submissionErrors);
    } else {
      setLoading(true);
      createSession();
    }
  };

  const options = [
    {
      key: 'a',
      text: 'Adult (19-64 years) - $88',
      value: 'adult',
    },
    {
      key: 't',
      text: 'Teen (13-19 year) - $55',
      value: 'teen',
    },
    {
      key: 's',
      text: 'Senior (65 and over) - $1',
      value: 'senior',
    },
  ];

  return (
    <Container>
      <MenuBar />
      <Container>
        <Grid
          textAlign='center'
          style={{ height: '75vh' }}
          verticalAlign='middle'
        >
          <Grid.Column style={{ maxWidth: 450 }}>
            <Header as='h2' color='teal' textAlign='center'>
              <Image src={logo} />
            </Header>
            <Form
              onSubmit={handleSubmit}
              size='large'
              warning={error.type === 'warning'}
              error={error.type === 'error'}
            >
              <Segment>
                <Form.Input
                  error={formErrors.includes('name')}
                  fluid
                  id='name'
                  name='name'
                  value={name}
                  onChange={handleNameChange}
                  placeholder='Name'
                />
                <Form.Input
                  error={formErrors.includes('email')}
                  fluid
                  id='email'
                  name='email'
                  value={email}
                  onChange={handleEmailChange}
                  placeholder='E-mail address'
                />
                <Form.Select
                  error={formErrors.includes('membership')}
                  fluid
                  id='membership'
                  name='membership'
                  value={membership}
                  onChange={handleMembershipChange}
                  options={options}
                  placeholder='Membership level'
                />
                <Form.Checkbox
                  error={formErrors.includes('tandc')}
                  id='tandc'
                  name='tandc'
                  value={tandc}
                  onChange={toggleTandc}
                  label='I acknowledge that I have read and agree to the GPC Australia terms and conditions'
                />
                <Message
                  warning
                  header={error.header}
                  content={error.message}
                />
                <Message error header={error.header} content={error.message} />
                <Button
                  disabled={formErrors.length > 0 || loading}
                  loading={loading}
                  fluid
                  secondary
                  size='large'
                >
                  Join GPC
                </Button>
              </Segment>
            </Form>
          </Grid.Column>
        </Grid>
      </Container>
    </Container>
  );
}
