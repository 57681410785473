import './App.css';
import { Route, Routes } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Home from './Home';
import Join from './Join';
import Members from './Members';
import UpdateMembership from './UpdateMembership';
import Rankings from './Rankings';
import Records from './Records';
import Resources from './Resources';
import ContactUs from './ContactUs';
import PromoLanding from './PromoLanding';
import { envFromUrl } from './utils';

import stripePubKeys from './stripePubKeys.json';

const env = envFromUrl();
const stripePubKey = stripePubKeys[env];

const stripePromise = loadStripe(stripePubKey);

function App() {
  return (
    <Elements stripe={stripePromise}>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/contactus' element={<ContactUs />} />
        <Route path='/join' element={<Join />} />
        <Route path='/members' element={<Members />} />
        <Route path='/update-membership/:key' element={<UpdateMembership />} />
        <Route path='/rankings' element={<Rankings />} />
        <Route path='/records' element={<Records />} />
        <Route path='/resources' element={<Resources />} />
        <Route path='/promo-landing/:hash' element={<PromoLanding />} />
      </Routes>
    </Elements>
  );
}

export default App;
