import { get } from 'aws-amplify/api';
import { useState } from 'react';
import {
  Container,
  Loader,
  Table,
  Form, 
} from 'semantic-ui-react';
import MenuBar from './MenuBar';
import { envFromUrl } from './utils';

import { Amplify } from 'aws-amplify';
const env = envFromUrl();
const amplifyconfig = await import(`./amplifyconfiguration/${env}.json`);

Amplify.configure(amplifyconfig);

export default function Records() {

    const [recordsLoading, setRecordsLoading] = useState(false);
    const [recordsLoaded, setRecordsLoaded] = useState(false);
    const [records, setRecords] = useState([]);
    const [sex, setSex] = useState("");
    const [sexText, setSexText] = useState("");
    const [age, setAge] = useState("");
    const [ageText, setAgeText] = useState("");
    const [eq, setEq] = useState("");
    const [eqText, setEqText] = useState("");
    const [wtcls, setWtCls] = useState("");
    const [wtclsText, setWtClsText] = useState("");
    const [weightClassOptions, setWeightClassOptions] = useState([]);
    const [fullSingle, setFullSingle] = useState("");
    const [fullSingleText, setFullSingleText] = useState("");

    function onChangeSex(_, data) {
        setSex(data.value);
        setSexText(optionLabelFromValue(sexOptions, data.value));
        // reset weight class in case sex change causes incongruency
        // eg: male 44kg or female 140+kg
        setWtCls("");
        setWtClsText("");
        if (data.value === "M") {
            setWeightClassOptions(mWeightClassOptions);
        }
        if (data.value === "F") {
            setWeightClassOptions(fWeightClassOptions);
        }
    }

    function onChangeAge(_, data) {
        setAge(data.value);
        setAgeText(optionLabelFromValue(ageOptions, data.value));
    }

    function onChangeEq(_, data) {
        setEq(data.value);
        setEqText(optionLabelFromValue(eqOptions, data.value));
        // if we switch to F8, set single lift
        if (data.value === "F8") {
            setFullSingle("single");
            setFullSingleText(optionLabelFromValue(fullSingleOptions, "single"));
        }
    }

    function onChangeWtCls(_, data) {
        setWtCls(data.value);
        setWtClsText(optionLabelFromValue(weightClassOptions, data.value));
    }

    function onChangeFullSingle(_, data) {
        setFullSingle(data.value);
        setFullSingleText(optionLabelFromValue(fullSingleOptions, data.value));
    }

    function getRecordsForCategory() {
        const _getRecordsForCategory = async () => {
            try {
                setRecordsLoading(true);
                setRecordsLoaded(false);
                const restOperation = get({
                    apiName: 'records',
                    path: '/records',
                    options: {
                        queryParams: {
                            sex: sex,
                            age: age,
                            eq: eq,
                            wtcls: wtcls,
                            fullSingle: fullSingle
                        }
                    }
                });
                const { body } = await restOperation.response;
                const responseJsonObj = await body.json();
                if (responseJsonObj?.error) {
                    throw new Error(responseJsonObj.error);
                }
                if (responseJsonObj?.records) {
                    setRecords(responseJsonObj.records);
                    setRecordsLoaded(true);
                }
                else {
                    throw new Error("Neither error nor records found in records API response");
                } 
            }
            catch (e) {
                console.error(`Error retrieving records: ${e.toString()}`);
            }
            finally {
                setRecordsLoading(false);
            }
        }
        _getRecordsForCategory();
    }

    function onSubmit(_) {
        if (
            sex === "" || age ===  "" || eq === "" || wtcls === "" || fullSingle === ""
        ) {
            return false;
        }
        getRecordsForCategory();
        return true;
    }

    //candidate for going in a utils.js
    function friendlyLiftName(abbr) {
        switch(abbr) {
            case "S":
                return "Squat";
            case "B":
                return "Bench Press";
            case "D":
                return "Deadlift";
            case "SBD":
                return "Total";
            // shouldn't get here but if we do, just
            // return the abbreviation unmolested
            default:
                return abbr;
        }
    }

    //candidate for going in a utils.js
    function formatLocalDate(ld) {
        if (!ld) {
            return "";
        }
        const fmt_month = ld.month < 10 ? "0" + ld.month.toString() : ld.month;
        const fmt_day = ld.day < 10 ? "0" + ld.day.toString() : ld.day; 
        return `${ld.year}-${fmt_month}-${fmt_day}`;
    }

    function optionLabelFromValue(options, value) {
        let retLabel = "";
        options.map((option) => {
            if (option["value"] === value) {
                retLabel = option["label"];
                return;
            }
        });
        return retLabel;
    }

    const sexOptions = [
        {"key": "M", "value": "M", "label": "Male"}, 
        {"key": "F", "value": "F", "label": "Female"},
    ];
    const ageOptions = [
        {"key": "O", "value": "O", "label": "Open"},
        {"key": "T1", "value": "T1", "label": "Teen 1 (13-15yo)"},
        {"key": "T2", "value": "T2", "label": "Teen 2 (16-17yo)"},
        {"key": "T3", "value": "T3", "label": "Teen 3 (18-19yo)"},
        {"key": "J", "value": "J", "label": "Juniors (20-23yo)"},
        {"key": "SM", "value": "SM", "label": "Sub-Masters (33-39yo)"},
        {"key": "M1", "value": "M1", "label": "Masters 1 (40-44yo)"},
        {"key": "M2", "value": "M2", "label": "Masters 2 (45-49yo)"},
        {"key": "M3", "value": "M3", "label": "Masters 3 (50-54yo)"},
        {"key": "M4", "value": "M4", "label": "Masters 4 (55-59yo)"},
        {"key": "M5", "value": "M5", "label": "Masters 5 (60-64yo)"},
        {"key": "M6", "value": "M6", "label": "Masters 6 (65-69yo)"},
        {"key": "M7", "value": "M7", "label": "Masters 7 (70-74yo)"},
        {"key": "M8", "value": "M8", "label": "Masters 8 (75-79yo)"},
        {"key": "M9", "value": "M9", "label": "Masters 9 (80+yo)"},
    ];
    const eqOptions = [
        {"key": "R", "value": "R", "label": "Raw (Wraps)"},
        {"key": "SL", "value": "SL", "label": "Raw (Sleeves)"},
        {"key": "E", "value": "E", "label": "Equippped"},
        {"key": "F8", "value": "F8", "label": "F8 Bench Shirt"},
    ];
    const mWeightClassOptions = [
        {"key": "56", "value": "56", "label": "56kg"},
        {"key": "60", "value": "60", "label": "60kg"},
        {"key": "67.5", "value": "67.5", "label": "67.5kg"},
        {"key": "75", "value": "75", "label": "75kg"},
        {"key": "82.5", "value": "82.5", "label": "82.5kg"},
        {"key": "90", "value": "90", "label": "90kg"},
        {"key": "100", "value": "100", "label": "100kg"},
        {"key": "110", "value": "110", "label": "110kg"},
        {"key": "125", "value": "125", "label": "125kg"},
        {"key": "140", "value": "140", "label": "140kg"},
        {"key": "140+", "value": "140+", "label": "140+kg"},
    ];
    const fWeightClassOptions = [
        {"key": "44", "value": "44", "label": "44kg"},
        {"key": "48", "value": "48", "label": "48kg"},
        {"key": "52", "value": "52", "label": "52kg"},
        {"key": "56", "value": "56", "label": "56kg"},
        {"key": "60", "value": "60", "label": "60kg"},
        {"key": "67.5", "value": "67.5", "label": "67.5kg"},
        {"key": "75", "value": "75", "label": "75kg"},
        {"key": "82.5", "value": "82.5", "label": "82.5kg"},
        {"key": "90", "value": "90", "label": "90kg"},
        {"key": "100", "value": "100", "label": "100kg"},
        {"key": "110", "value": "110", "label": "110kg"},
        {"key": "110+", "value": "110+", "label": "110+kg"},
    ];
    const fullSingleOptions = [
        {"key": "full", "value": "full", "label": "Full 3-Lift Meet"},
        {"key": "single", "value": "single", "label": "Single Lift"},
    ];
    return (
        <Container>
            <MenuBar />
            <Container>
                <Form onSubmit={onSubmit}>
                    <Form.Group widths="equal" inline>
                        <Form.Select
                            label="Sex"
                            onChange={onChangeSex}
                            options={sexOptions}
                            text={sexText}
                        />
                        <Form.Select
                            label="Age"
                            onChange={onChangeAge}
                            options={ageOptions}
                            text={ageText}
                        />
                        <Form.Select
                            label="Equipment"
                            onChange={onChangeEq}
                            options={eqOptions}
                            text={eqText}
                        />
                        <Form.Select
                            label="Weight Class"
                            onChange={onChangeWtCls}
                            options={weightClassOptions}
                            text={wtclsText}
                        />
                        <Form.Select
                            label="Event Type"
                            onChange={onChangeFullSingle}
                            options={fullSingleOptions}
                            text={fullSingleText}
                        />
                        <Form.Button type="submit">Get records</Form.Button>
                    </Form.Group>
                </Form>
                {recordsLoading ? ( 
                    <Loader active inline='centered' />
                ) : (
                    <br />
                )}
                {recordsLoaded ? ( 
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Lift</Table.HeaderCell>
                                <Table.HeaderCell>Lifter</Table.HeaderCell>
                                <Table.HeaderCell>Weight</Table.HeaderCell>
                                <Table.HeaderCell>Event</Table.HeaderCell>
                                <Table.HeaderCell>State</Table.HeaderCell>
                                <Table.HeaderCell>Date</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {records.map(record => ( 
                                <Table.Row>
                                    <Table.Cell>{friendlyLiftName(record.lift)}</Table.Cell>
                                    <Table.Cell>{record.lifter_name}</Table.Cell>
                                    <Table.Cell>{record.weight}kg</Table.Cell>
                                    <Table.Cell>{record.event_name}</Table.Cell>
                                    <Table.Cell>{record.event_state}</Table.Cell>
                                    <Table.Cell>{formatLocalDate(record.event_local_date)}</Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                ) : (
                    <br />
                )}
                <p>
                    <b>NOTE:</b> Raw (Wraps) and Raw (Sleeves) share records for Bench Press and Deadlift,&nbsp;
                    Squat and Total records are separate.
                </p>
            </Container>
        </Container>
    );
}
