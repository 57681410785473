import { Container, Header, List, ListItem } from 'semantic-ui-react';
import MenuBar from './MenuBar';
import { decodeEmail, envFromUrl } from './utils';

import { Amplify } from 'aws-amplify';
const env = envFromUrl();
const amplifyconfig = await import(`./amplifyconfiguration/${env}.json`);

Amplify.configure(amplifyconfig);

export default function ContactUs() {
    return (
        <Container>
            <MenuBar />
            <Container>
                <p>
                    For any information you need, feel free to contact our
                    committee, or your local/state representative.
                </p>
                <Header as="h2">GPC Australia Committee</Header>
                <List bulleted>
                    <ListItem>Treasurer: Vicki Jewson - {decodeEmail('gernfhere$tcpnhfgenyvn_pbz')}</ListItem>
                    <ListItem>Secretary: Mikki Wescombe - {decodeEmail('frpergnel$tcpnhfgenyvn_pbz')}</ListItem>
                </List>
            </Container>
        </Container>
    );
}